"use client";

import { sendGTMEvent } from "@next/third-parties/google";
import { parse } from "accept-language-parser";
import {
  CartItem,
  ReserveCancelRegister,
  ReserveCancelRegisterSet,
  SearchResult,
} from "../definitions";
import { PlainMessage } from "@bufbuild/protobuf";
import {
  Event,
  EventDetails,
} from "@egocentric-systems/ts-apis/booking_gateway/v1/events_pb";
import { getCookieSettings } from "~/hooks/useCookieSettings";
import {
  Order_BundleItem,
  Order_SeatItem,
} from "@egocentric-systems/ts-apis/booking/types/v1/order_pb";

function isThirdPartyEnabled(): boolean {
  const { necessary, thirdParty } = getCookieSettings();
  return necessary && thirdParty;
}

export function gtmCartExpired(items: CartItem[]): void {
  if (!isThirdPartyEnabled()) return;

  sendGTMEvent({
    event: "cart_expired",
    ecommerce: {
      cart: items,
    },
  });
}

export function gtmReserveSeat(
  register: ReserveCancelRegister,
  items: CartItem[],
): void {
  if (!isThirdPartyEnabled()) return;

  sendGTMEvent({
    event: "reserve_seat",
    ecommerce: {
      reservation: register,
      cart: items,
    },
  });
}

export function gtmCancelSeat(
  register: ReserveCancelRegister,
  items: CartItem[],
): void {
  if (!isThirdPartyEnabled()) return;

  sendGTMEvent({
    event: "cancel_seat",
    ecommerce: {
      reservation: register,
      cart: items,
    },
  });
}

export function gtmSetSeat(
  register: ReserveCancelRegisterSet,
  items: CartItem[],
): void {
  if (!isThirdPartyEnabled()) return;

  sendGTMEvent({
    event: "set_seat",
    ecommerce: {
      reservation: register,
      cart: items,
    },
  });
}

export function gtmViewUpcomingEvent(event: PlainMessage<Event>): void {
  if (!isThirdPartyEnabled()) return;

  sendGTMEvent({
    event: "view_upcoming_event",
    ecommerce: {
      event,
    },
  });
}

export function gtmPageView(pathname: string, lng: string): void {
  if (!isThirdPartyEnabled()) return;
  if (typeof navigator === "undefined") return;

  const browserLng = parse(navigator.language)[0]?.code;

  sendGTMEvent({
    event: "page_view",
    ecommerce: null,
    pathname,
    url: window.location.href,
    pageTitle: document.title,
    lng,
    browserLng,
  });
}

export function gtmChangeLanguage(
  currentLangugage: string,
  targetLanguage: string,
): void {
  if (!isThirdPartyEnabled()) return;

  if (typeof navigator === "undefined") return;
  const browserLng = parse(navigator.language)[0]?.code;

  sendGTMEvent({
    event: "change_language",
    ecommerce: null,
    currentLangugage,
    targetLanguage,
    browserLng,
  });
}

export function gtmOpenEventVenue(details: PlainMessage<EventDetails>): void {
  if (!isThirdPartyEnabled()) return;

  sendGTMEvent({
    event: "open_event_venue",
    ecommerce: {
      event_id: details.event?.id,
      event_name: details.event?.name,
      series_id: details.event?.seriesId,
      venue: details.venue,
    },
  });
}

export function gtmViewFaqItem(title: string, text: string): void {
  if (!isThirdPartyEnabled()) return;

  sendGTMEvent({
    event: "view_faq_item",
    ecommerce: {
      faq_item: {
        title,
        text,
      },
    },
  });
}

export function gtmOpenSearch(): void {
  if (!isThirdPartyEnabled()) return;

  sendGTMEvent({
    event: "open_search",
    ecommerce: null,
  });
}

export function gtmCloseSearch(): void {
  if (!isThirdPartyEnabled()) return;

  sendGTMEvent({
    event: "close_search",
    ecommerce: null,
  });
}

export function gtmSearchResults(
  search: string,
  results: SearchResult[],
): void {
  if (!isThirdPartyEnabled()) return;

  sendGTMEvent({
    event: "search_results",
    ecommerce: {
      search,
      results,
    },
  });
}

type PurchaseProps = {
  value: number;
  currency: string;
  shipping_cost: number;
  shipping_method: string;
  seats: PlainMessage<Order_SeatItem>[];
  bundles: PlainMessage<Order_BundleItem>[];
  order_id: string;
};

export function gtmPurchase(props: PurchaseProps): void {
  if (!isThirdPartyEnabled()) return;

  sendGTMEvent({
    event: "purchase",
    ecommerce: {
      ...props,
    },
  });
}
