"use client";

import { Interceptor, createPromiseClient } from "@connectrpc/connect";
import { Reservations } from "@egocentric-systems/ts-apis/booking_gateway/v1/reservations_connect";
import { getHost, getLocale } from "./utils";
import { createConnectTransport } from "@connectrpc/connect-web";
import {
  ACCEPT_LANGUAGE_HEADER,
  CONTENT_TYPE_HEADER,
  EGOSYS_AUTH_TOKEN,
  EGOSYS_ORIGIN_HOSTNAME_HEADER,
} from "../definitions";
import Cookies from "universal-cookie";
import { logIfDev } from "../utils";

const loggingInterceptor: Interceptor = (next) => async (req) => {
  logIfDev(
    `[reservations] ${req.method.name} ${req.url.toString()} [${EGOSYS_ORIGIN_HOSTNAME_HEADER}: ${req.header.get(EGOSYS_ORIGIN_HOSTNAME_HEADER)}] [token: ${req.header.get("Authorization")}]`,
  );
  return await next(req);
};

const headerInterceptor: Interceptor = (next) => async (req) => {
  req.header.set(EGOSYS_ORIGIN_HOSTNAME_HEADER, getHost());
  req.header.set(ACCEPT_LANGUAGE_HEADER, getLocale());
  req.header.set(CONTENT_TYPE_HEADER, "application/json");

  const cookies = new Cookies();
  const token = cookies.get(EGOSYS_AUTH_TOKEN);

  if (token) {
    req.header.set("Authorization", `Bearer ${token}`);
  }

  return await next(req);
};

export const reservation = createPromiseClient(
  Reservations,
  createConnectTransport({
    baseUrl: process.env.NEXT_PUBLIC_API_GATEWAY as string,
    useHttpGet: false,
    interceptors: [headerInterceptor, loggingInterceptor],
  }),
);
