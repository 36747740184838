"use client";

import { PlainMessage } from "@bufbuild/protobuf";
import { OrderRequest } from "@egocentric-systems/ts-apis/booking_gateway/v1/orders_pb";
import { Address } from "@egocentric-systems/ts-apis/types/v1/address_pb";
import { z } from "zod";
import { Paths } from "../Paths";
import { CheckoutSchema } from "./schemas/checkout";
import { useCart } from "~/hooks/useCart";
import { getLocale } from "./utils";
import {
  DiscountConfig,
  OrderBundleConfig,
} from "@egocentric-systems/ts-apis/booking/types/v1/order_pb";
import { CartItem } from "../definitions";
import { Discount_Target } from "@egocentric-systems/ts-apis/voucher/types/v1/usage_pb";
import {
  CancelBundleRequest,
  ReserveBundleRequest,
} from "@egocentric-systems/ts-apis/booking/types/v1/reservation_pb";

function isValidAddress(
  address: z.infer<CheckoutSchema>["address"] | undefined,
): boolean {
  return (
    !!address &&
    !!address.addressLine1 &&
    !!address.countryCode &&
    !!address.locality &&
    !!address.name &&
    !!address.postalCode
  );
}

export function createAddress(
  data: z.infer<CheckoutSchema> | null,
  locale: string,
): PlainMessage<Address> | undefined {
  if (data === null) return undefined;

  const { deliveryAddress, name } = data;

  if (!isValidAddress(deliveryAddress)) {
    return undefined;
  }

  return {
    countryCode: deliveryAddress?.countryCode ?? locale,
    locality: deliveryAddress?.locality ?? "",
    postalCode: deliveryAddress?.postalCode ?? "",
    addressLine1: deliveryAddress?.addressLine1 ?? "",
    addressLine2: deliveryAddress?.addressLine2 ?? "",
    name,
    regionCode: deliveryAddress?.regionCode ?? "",
  };
}

export function createBillingAddress(
  data: z.infer<CheckoutSchema> | null,
  locale: string,
): PlainMessage<Address> | undefined {
  if (data === null) return undefined;

  const { deliveryDiffers, address, name } = data;

  if (!deliveryDiffers || !isValidAddress(address)) {
    return undefined;
  }

  return {
    locality: address?.locality ?? "",
    regionCode: address?.regionCode ?? "",
    countryCode: address?.countryCode ?? locale,
    addressLine1: address?.addressLine1 ?? "",
    addressLine2: address?.addressLine2 ?? "",
    postalCode: address?.postalCode ?? "",
    name: address?.name ?? name,
  };
}

type Props = {
  data?: z.infer<CheckoutSchema> | undefined;
  paypalOrderId: string | undefined;
  deliveryAddress: PlainMessage<Address> | undefined;
  billingAddress: PlainMessage<Address> | undefined;
  identityId: string | undefined;
};

export function createOrderRequest(props: Props): PlainMessage<OrderRequest> {
  const { items, discounts, shipmentMethod, reservationToken } =
    useCart.getState();

  const language = getLocale();

  if (!shipmentMethod) {
    throw new Error("Shipment method is required");
  }

  const ticketDiscounts = items.filter(
    (item) => !!item.discount?.value,
  ) as Array<Required<CartItem>>;

  return {
    customer: props.data
      ? {
          email: props.data.email,
          fullname: props.data.name,
          language,
          shippingAddress: props.deliveryAddress,
          billingAddress: props.billingAddress,
          companyName: props.data.company ?? "",
          vatId: props.data.vatNumber ?? "",
          identityId: props.identityId ?? "",
        }
      : undefined,
    reservationToken: reservationToken ?? "",
    shipmentMethod: shipmentMethod,
    seatConfigs: items
      .filter(
        (item) => item.quantity > 0 && item.eventOrBundle.case === "event",
      )
      .map((item) => ({
        eventId: item.eventOrBundle.value.id,
        sectionId: item.section.id,
        priceId: item.price.id,
        upgrades: item.upgrades ?? [],
        seating: !item.seat
          ? {
              case: "seatCount",
              value: item.quantity,
            }
          : {
              case: "seatIds",
              value: { ids: [item.seat.id] },
            },
      })),
    bundleConfigs: createOrderBundleConfigs(items),
    paymentConfig: {
      stripe: {
        stripeReturnPath: Paths.CHECKOUT,
      },
      ...(props.paypalOrderId && {
        paypal: { expressOrderId: props.paypalOrderId },
      }),
    },
    discountConfigs: [
      ...ticketDiscounts.map(
        (ticket) =>
          ({
            reference: ticket.discount,
            eventId: ticket.eventOrBundle.value?.id,
            priceId: ticket.price.id,
          }) satisfies PlainMessage<DiscountConfig>,
      ),
      ...discounts.vouchers
        .filter((voucher) => voucher.discount?.target === Discount_Target.ORDER)
        .map(
          (voucher) =>
            ({
              reference: {
                case: "voucherId",
                value: voucher.id,
              },
            }) satisfies PlainMessage<DiscountConfig>,
        ),
      ...discounts.benefits
        .filter((benefit) => benefit.discount?.target === Discount_Target.ORDER)
        .map(
          (benefit) =>
            ({
              reference: {
                case: "benefitId",
                value: benefit.id,
              },
            }) satisfies PlainMessage<DiscountConfig>,
        ),
    ],
    // TODO: Implement gift cards
    giftcardIds: [],
  };
}

export function createReserveOrCancelBundleRequest(
  item: CartItem,
): Omit<
  PlainMessage<ReserveBundleRequest | CancelBundleRequest>,
  "reservationToken"
>[] {
  const configurations: Omit<
    PlainMessage<ReserveBundleRequest | CancelBundleRequest>,
    "reservationToken"
  >[] = [];

  for (let i = 0; i < item.quantity; i++) {
    configurations.push({
      bundleId: item.eventOrBundle.value.id,
      section:
        item.eventOrBundle.case === "bundle" &&
        item.eventOrBundle.value.seatmapId &&
        item.section.seatmapSectionId
          ? {
              case: "seatmapSectionId",
              value: {
                seatmapId: item.eventOrBundle.value.seatmapId,
                seatmapSectionId: item.section.seatmapSectionId,
              },
            }
          : {
              case: "gaSectionId",
              value: item.section.id,
            },
      seatsByEvent: Object.fromEntries(
        (item.eventOrBundle.value as { eventIds: string[] }).eventIds.map(
          (eventId) => [
            eventId,
            {
              seating: !item.seat
                ? {
                    case: "seatCount",
                    value: 1,
                  }
                : {
                    case: "seatIds",
                    value: { ids: [item.seat.id] },
                  },
            },
          ],
        ),
      ),
    });
  }

  return configurations;
}

export function createOrderBundleConfigs(items: CartItem[]) {
  const configs: Array<PlainMessage<OrderBundleConfig>> = [];

  for (const item of items.filter(
    (item) => item.eventOrBundle.case === "bundle",
  )) {
    for (let i = 0; i < item.quantity; i++) {
      configs.push({
        bundleId: item.eventOrBundle.value.id,
        priceId: item.price.id,
        upgrades: item.upgrades ?? [],
        section:
          item.eventOrBundle.case === "bundle" &&
          item.eventOrBundle.value.seatmapId &&
          item.section.seatmapSectionId
            ? {
                case: "seatmapSectionId",
                value: {
                  seatmapId: item.eventOrBundle.value.seatmapId,
                  seatmapSectionId: item.section.seatmapSectionId,
                },
              }
            : {
                case: "gaSectionId",
                value: item.section.id,
              },
        seatsByEvent: Object.fromEntries(
          item.eventOrBundle.case === "bundle"
            ? item.eventOrBundle.value.eventIds.map((eventId) => [
                eventId,
                {
                  seating: !item.seat
                    ? {
                        case: "seatCount",
                        value: 1,
                      }
                    : {
                        case: "seatIds",
                        value: { ids: [item.seat.id] },
                      },
                },
              ])
            : [],
        ),
      });
    }
  }

  return configs;
}
