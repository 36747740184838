import { useMemo } from "react";
import Cookies from "universal-cookie";
import { useCookies } from "react-cookie";

import {
  COOKIE_PREFERENCES_KEY,
  DEFAULT_COOKIE_EXPIRE,
  DEFAULT_COOKIE_MAX_AGE,
} from "~/lib/definitions";

type CookieSettingsBase = {
  performance: boolean;
  thirdParty: boolean;
  necessary: boolean;
};

type CookieSettings = CookieSettingsBase & {
  acceptAll: () => void;
  acceptNecessary: () => void;
  setThirdParty: (value: boolean) => void;
  setPerformance: (value: boolean) => void;
  remove: () => void;
};

export function getCookieSettings(): CookieSettingsBase {
  const cookies = new Cookies();

  const currentSettings = cookies.get(COOKIE_PREFERENCES_KEY);

  if (!currentSettings) {
    return {
      necessary: false,
      thirdParty: false,
      performance: false,
    };
  }

  if (
    ["necessary", "thirdParty", "performance"].some(
      (key) => !currentSettings[key],
    )
  ) {
    return {
      necessary: false,
      thirdParty: false,
      performance: false,
    };
  }

  return {
    necessary: Boolean(currentSettings["necessary"]),
    thirdParty: Boolean(currentSettings["thirdParty"]),
    performance: Boolean(currentSettings["performance"]),
  };
}

export function useCookieSettings(): CookieSettings {
  const [cookies, setCookies, removeCookies] = useCookies([
    COOKIE_PREFERENCES_KEY,
  ]);

  const settings = useMemo(() => {
    const currentSettings = cookies[COOKIE_PREFERENCES_KEY];

    if (!currentSettings) {
      return {
        performance: false,
        thirdParty: false,
        necessary: false,
      };
    }

    return {
      performance: Boolean(currentSettings.performance),
      thirdParty: Boolean(currentSettings.thirdParty),
      necessary: Boolean(currentSettings.necessary),
    };
  }, [cookies]);

  const acceptAll = () => {
    setCookies(
      COOKIE_PREFERENCES_KEY,
      {
        performance: true,
        thirdParty: true,
        necessary: true,
      },
      {
        path: "/",
        maxAge: DEFAULT_COOKIE_MAX_AGE,
        expires: DEFAULT_COOKIE_EXPIRE,
        sameSite: "strict",
      },
    );
  };

  const acceptNecessary = () => {
    setCookies(
      COOKIE_PREFERENCES_KEY,
      {
        performance: false,
        thirdParty: false,
        necessary: true,
      },
      {
        path: "/",
        maxAge: DEFAULT_COOKIE_MAX_AGE,
        expires: DEFAULT_COOKIE_EXPIRE,
        sameSite: "strict",
      },
    );
  };

  const setThirdParty = (value: boolean) => {
    setCookies(
      COOKIE_PREFERENCES_KEY,
      {
        ...settings,
        thirdParty: value,
      },
      {
        path: "/",
        maxAge: DEFAULT_COOKIE_MAX_AGE,
        expires: DEFAULT_COOKIE_EXPIRE,
        sameSite: "strict",
      },
    );
  };

  const setPerformance = (value: boolean) => {
    setCookies(
      COOKIE_PREFERENCES_KEY,
      {
        ...settings,
        performance: value,
      },
      {
        path: "/",
        maxAge: DEFAULT_COOKIE_MAX_AGE,
        expires: DEFAULT_COOKIE_EXPIRE,
        sameSite: "strict",
      },
    );
  };

  const remove = () => {
    removeCookies(COOKIE_PREFERENCES_KEY, {
      path: "/",
    });
  };

  return {
    performance: settings.performance,
    thirdParty: settings.thirdParty,
    necessary: settings.necessary,
    acceptAll,
    acceptNecessary,
    setPerformance,
    setThirdParty,
    remove,
  };
}
